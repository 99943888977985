import { Firebase } from '@linktivity/link-utils';
import { format } from 'date-fns';
import { message } from '@linktivity/link-ui';
import i18n from 'i18next';
import { BaseAuth } from '@sagano/share/stores';
import { DataTypeMessageItem } from '@sagano/share/types';
import { getters, generateId } from '../utils';

export type { User, UserCredential } from '@linktivity/link-utils';

const firebaseConfig = {
  apiKey: import.meta.env.VITE_APP_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_APP_FIREBASE_DATABASE_URL,
  storageBucket: import.meta.env.VITE_APP_FIREBAE_STORAGE_BUCKET
};

const tenantId = import.meta.env.VITE_APP_FIREBASE_TENANT_ID;
const DEFAULT_SORT_ID = 0;
const SORT_ID_INCREMENT = 10;

export const MESSAGES_PATH = 'messages';

class ShareAuth extends Firebase {
  constructor(auth: BaseAuth) {
    super(firebaseConfig);
    this.setTenantId(tenantId);

    this.auth.onAuthStateChanged(user => {
      if (user) {
        auth.setUser(user);
      } else {
        auth.clearUser();
        auth.clearAllStorage();
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  showErrorMessage = (error: any) => {
    const errorCode = error.code;
    const errorMessage = i18n.exists(`share.firebase.${errorCode}`)
      ? i18n.t(`share.firebase.${errorCode}`)
      : error.message;
    message.error(errorMessage);
  };

  private async getNextSortId(type: string): Promise<number> {
    const snapshot = await super
      .getDatabaseData(`${MESSAGES_PATH}/${type}`)
      .catch(error => {
        this.showErrorMessage(error);
        throw error;
      });

    if (!snapshot.exists()) {
      return DEFAULT_SORT_ID;
    }

    const messages = Object.values(
      snapshot.val() || {}
    ) as DataTypeMessageItem[];
    if (messages.length === 0) {
      return DEFAULT_SORT_ID;
    }

    const maxSortId = Math.max(...messages.map(msg => msg.sortId ?? 0));
    return maxSortId + SORT_ID_INCREMENT;
  }

  private sortMessages(messages: DataTypeMessageItem[]) {
    return messages
      .map((msg, index) => ({
        ...msg,
        sortId: msg.sortId ?? index * SORT_ID_INCREMENT
      }))
      .sort((a, b) => b.sortId - a.sortId);
  }

  createMessage = async (
    type: string,
    data: DataTypeMessageItem
  ): Promise<void> => {
    const id = getters.mutationId;
    const sortId = await this.getNextSortId(type);
    const messageData = {
      ...data,
      id,
      createTime: this.serverTimestamp(),
      sortId
    };

    return this.setDatabase(
      `${MESSAGES_PATH}/${type}/${id}`,
      messageData
    ).catch(error => {
      this.showErrorMessage(error);
      throw error;
    });
  };

  updateMessage = (
    type: string,
    id: string,
    data: DataTypeMessageItem
  ): Promise<void> => {
    const updateData = {
      ...data,
      updateTime: this.serverTimestamp()
    };
    return this.updateDatabase(
      `${MESSAGES_PATH}/${type}/${id}`,
      updateData
    ).catch(error => {
      this.showErrorMessage(error);
      throw error;
    });
  };

  deleteMessage = (type: string, id: string): Promise<void> => {
    return this.deleteDatabase(`${MESSAGES_PATH}/${type}/${id}`).catch(
      error => {
        this.showErrorMessage(error);
        throw error;
      }
    );
  };

  getMessages = async () => {
    const snapshot = await this.getDatabaseData(`${MESSAGES_PATH}`).catch(
      error => {
        this.showErrorMessage(error);
        throw error;
      }
    );

    if (!snapshot.exists()) {
      return null;
    }

    const data = snapshot.val() as {
      [type: string]: {
        [id: string]: DataTypeMessageItem;
      };
    };
    Object.keys(data).forEach(type => {
      if (Object.prototype.hasOwnProperty.call(data, type)) {
        const messages = Object.values(data[type] || {});
        const sortedMessages = this.sortMessages(
          messages as DataTypeMessageItem[]
        );
        data[type] = Object.fromEntries(
          sortedMessages.map(msg => [msg.id, msg])
        );
      }
    });

    return data;
  };

  getMessageById = async (type: string, id: string) => {
    const snapshot = await this.getDatabaseData(
      `${MESSAGES_PATH}/${type}/${id}`
    ).catch(error => {
      this.showErrorMessage(error);
      throw error;
    });

    if (!snapshot.exists()) {
      return null;
    }

    const messageData = snapshot.val() as DataTypeMessageItem;

    return messageData;
  };

  updateMessagesSortId = (
    type: string,
    messages: DataTypeMessageItem[]
  ): Promise<void> => {
    const updatePromises = messages.map((message, index) => {
      const reversedIndex = messages.length - index - 1;
      const updatedMessage = {
        ...message,
        sortId: (reversedIndex + 1) * SORT_ID_INCREMENT,
        updateTime: this.serverTimestamp()
      };
      return this.updateDatabase(
        `${MESSAGES_PATH}/${type}/${message.id}`,
        updatedMessage
      );
    });
    return Promise.all(updatePromises)
      .then(() => undefined)
      .catch(error => {
        this.showErrorMessage(error);
        throw error;
      });
  };

  uploadMessageFile = (file: File) => {
    const name = file.name.substring(0, file.name.lastIndexOf('.'));
    const ext = file.name.split('.').pop();
    const id = generateId();
    const path = `${MESSAGES_PATH}/${format(
      new Date(),
      'yyyy-MM-dd'
    )}/${name}_${id}.${ext}`;

    return this.uploadFile(file, path)
      .then(() =>
        this.downloadFile(path).then(downloadURL => ({
          id: id,
          filename: file.name,
          downloadURL
        }))
      )
      .catch(error => {
        this.showErrorMessage(error);
        throw error;
      });
  };
}

export default ShareAuth;
