/* eslint-disable react-refresh/only-export-components */
import { lazy, Suspense } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Navigate,
  useRouteError
} from 'react-router-dom';
import { BaseLayout, DetailLayout } from '@sagano/agent/layouts';
import PrivateRoute from './PrivateRoute';

const Notification = lazy(() => import('@sagano/agent/views/Notification'));
const AgentDetail = lazy(() => import('@sagano/agent/views/Agent/Detail'));
const GroupDetail = lazy(() => import('@sagano/agent/views/Agent/Group'));
const AgentUpdate = lazy(() => import('@sagano/agent/views/Agent/Update'));
const BookingCreate = lazy(() => import('@sagano/agent/views/Booking/Create'));
const BookingConfirm = lazy(
  () => import('@sagano/agent/views/Booking/Confirm')
);
const BookingList = lazy(() => import('@sagano/agent/views/Booking/List'));

const BookingDetail = lazy(() => import('@sagano/agent/views/Booking/Detail'));
const BookingCancel = lazy(() => import('@sagano/agent/views/Booking/Cancel'));
const BookingUpdate = lazy(() => import('@sagano/agent/views/Booking/Update'));
const BookingHistory = lazy(
  () => import('@sagano/agent/views/Booking/History')
);
const ChangePassword = lazy(() => import('@sagano/agent/views/ChangePassword'));
const Dashboard = lazy(() => import('@sagano/agent/views/Dashboard'));
const Message = lazy(() => import('@sagano/agent/views/Message'));
const Login = lazy(() => import('@sagano/agent/views/Login'));
const NotFound = lazy(() => import('@sagano/share/views/NotFound'));
const ResetPassword = lazy(() => import('@sagano/agent/views/ResetPassword'));
const SearchAllotments = lazy(
  () => import('@sagano/agent/views/SearchAllotments')
);
const SearchAllotmentsByDate = lazy(
  () => import('@sagano/agent/views/SearchAllotments/ByDate')
);
const Sales = lazy(() => import('@sagano/agent/views/Sales'));
const MonthlyReport = lazy(() => import('@sagano/agent/views/MonthlyReport'));
const PaymentByGroup = lazy(
  () => import('@sagano/agent/views/MonthlyReport/PaymentByGroup')
);

const Payment = lazy(() => import('@sagano/agent/views/Payment'));

function RootErrorBoundary() {
  const error = useRouteError() as Error;
  if (error.message.includes('Failed to fetch dynamically imported module')) {
    location.reload();
  }
  return null;
}

const lazyView = (view: JSX.Element) => {
  return <Suspense fallback="Loading...">{view}</Suspense>;
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<RootErrorBoundary />}>
      <Route element={<PrivateRoute />}>
        <Route path="/" element={<BaseLayout />}>
          <Route index element={<Navigate to="dashboard" replace />} />
          <Route path="dashboard">
            <Route index element={lazyView(<Dashboard />)} />
            <Route path=":messageId" element={lazyView(<Message />)} />
          </Route>

          <Route path="notifications" element={lazyView(<Notification />)} />
          <Route path="/agent">
            <Route index element={lazyView(<AgentDetail />)} />
            <Route path="update" element={lazyView(<AgentUpdate />)} />
            <Route path="groups/:groupId" element={lazyView(<GroupDetail />)} />
          </Route>
          <Route path="/bookings/">
            <Route index element={lazyView(<BookingList />)} />
            <Route path="confirm" element={lazyView(<BookingConfirm />)} />
            <Route path="create" element={lazyView(<BookingCreate />)} />
            <Route path=":bookingId" element={<DetailLayout />}>
              <Route index element={lazyView(<BookingDetail />)} />
              <Route path="update" element={lazyView(<BookingUpdate />)} />
              <Route path="copy" element={lazyView(<BookingCreate />)} />
              <Route path="cancel" element={lazyView(<BookingCancel />)} />
              <Route path="histories" element={lazyView(<BookingHistory />)} />
            </Route>
          </Route>
          <Route path="sales" element={lazyView(<Sales />)} />
          <Route path="monthly-report">
            <Route index element={lazyView(<MonthlyReport />)} />
            <Route path="payment" element={lazyView(<PaymentByGroup />)} />
          </Route>
          <Route
            path="payment/:bookingId/:orderId"
            element={lazyView(<Payment />)}
          />
          <Route
            path="change-password"
            element={lazyView(<ChangePassword />)}
          />
          <Route path="search-allotments">
            <Route index element={lazyView(<SearchAllotments />)} />
            <Route path=":date">
              <Route index element={lazyView(<SearchAllotmentsByDate />)} />
            </Route>
          </Route>
          <Route path="*" element={lazyView(<NotFound />)} />
        </Route>
      </Route>
      <Route path="/reset-password" element={lazyView(<ResetPassword />)} />
      <Route path="/login" element={lazyView(<Login />)} />
    </Route>
  )
);

export default router;
