import { FILE_HOST } from '../constants';
import { IDirection } from '../services/const';
import { ServiceSummary } from '../services/types';

// eslint-disable-next-line
export const noop = () => {};

export const formatPrice = (price?: string | number) => {
  if (price !== undefined) {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } else {
    return '';
  }
};

export const formatCancelPrice = (price: number) => {
  return Math.ceil(price / 10) * 10;
};

export const getVoucherLink = (bookingId: string, bookingToken: string) => {
  return `${FILE_HOST}/voucher/${bookingId}/${bookingToken}`;
};

export const base64ToBlob = (base64: string, mime: string) => {
  const bin = atob(base64.replace(/^.*,/, ''));
  const buffer = new Uint8Array(bin.length);
  for (let i = 0; i < bin.length; i++) {
    buffer[i] = bin.charCodeAt(i);
  }
  const blob = new Blob([buffer.buffer], { type: mime });
  return blob;
};

export const downloadCSV = (csvBase64: string, fileName: string) => {
  const blob = base64ToBlob(csvBase64, 'text/csv');
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const getPathParams = (path: string, searchParams: URLSearchParams) => {
  return `${path}${
    searchParams.toString() ? `?${searchParams.toString()}` : ''
  }`;
};

export const formatServicesSummary = (serviceSummaries: ServiceSummary[]) => {
  if (!serviceSummaries) return [];

  return serviceSummaries.sort((a, b) => {
    if (a.directionId !== b.directionId) {
      return a.directionId === IDirection.DOWN ? -1 : 1;
    }
    return (
      Number.parseInt(a.serviceId as string, 10) -
      Number.parseInt(b.serviceId as string, 10)
    );
  });
};

export const generateId = () => {
  return Math.floor(Math.random() * 1e8).toString();
};
