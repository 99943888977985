import { uuid } from '@linktivity/link-utils';
export { EMAIL_REGEX, PASSWORD_REGEX, NUMBER_REGEX } from './regex';
export {
  noop,
  formatPrice,
  formatCancelPrice,
  getVoucherLink,
  downloadCSV,
  getPathParams,
  formatServicesSummary,
  generateId
} from './misc';
export {
  dateFormat,
  yearMonthFormat,
  timeFormat,
  timestampFormat,
  today,
  currentMonthStart,
  currentMonthEnd,
  formatDate,
  validDateRange,
  validTimeRange
} from './date';

export const getters = {
  get mutationId() {
    return uuid();
  }
};
